<template>
  <CreativeRotation v-if="creativeRotationAvailable" />
  <CreativesSelect />
</template>

<script setup lang="ts">
import CreativeRotation from './CreativeRotation.vue'
import { CreativesSelect } from './CreativesSelect'
import { useCardCreatives } from './useCardCreatives'

const { creativeRotationAvailable } = useCardCreatives()!
</script>
